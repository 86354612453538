* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --black: #0F0F0F;
  --black-transp: #0F0F0Fcc;

  --grey: #272727;
  --grey-transp: #27272780;
}

body {
  background-color: #d3d3d3cc;
  color: var(--black);
  font-family: 'Poppins', sans-serif;
}

.navbar {
  background-color: var(--grey);
}

.nav-items {
  padding: 10px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-value {
  font-family: inherit;
  border-radius: 5px;
  border: none;
  padding: 5px;
}

.result {
  display: flex;
  justify-content: center;
}

/* GRID */
.grid {
  width: 80%;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
}

.error {
  color: #a03737;
  margin-top: 1rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* GRID ITEM */
.grid-item {
  width: 250px;
  border-radius: 5px;
  background-color: var(--grey-transp);
  margin: 25px;
  min-height: 300px;
}

.item-img {
  display: flex;
  justify-content: center;
}

.item-img img{
  width: 250px;
  height: auto;
}

.item-info {
  padding: 0 10px;
}

p .info {
  color: var(--black-transp);
}

.item-info h2 {
  font-size: 1.2rem;
  text-align: center;
}